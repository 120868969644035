var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "exam-manage"
  }, [_vm._m(0), _c('div', {
    staticClass: "table"
  }, [_c('el-table', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "data": _vm.studentList,
      "border": "",
      "size": "small",
      "max-height": "500px"
    }
  }, [_c('el-table-column', {
    attrs: {
      "type": "index",
      "width": "50",
      "label": "序号"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "name",
      "label": "姓名"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "examineeNo",
      "label": "准考证号"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "idcardNo",
      "label": "身份证号"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "phone",
      "label": "手机号"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "submitDttm",
      "label": "导入时间"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.importDttm) + " ")];
      }
    }])
  })], 1)], 1), _vm.total > 0 ? _c('p', {
    staticStyle: {
      "text-align": "center",
      "margin-top": "20px"
    }
  }, [_c('el-pagination', {
    attrs: {
      "current-page": _vm.current,
      "page-sizes": [20, 30, 50, 100],
      "page-size": _vm.size,
      "layout": "total, sizes, prev, pager, next, jumper",
      "total": _vm.total
    },
    on: {
      "size-change": _vm.handleSizeChange,
      "current-change": _vm.handleCurrentChange
    }
  })], 1) : _vm._e()]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "tab"
  }, [_c('div', {
    staticClass: "active"
  }, [_c('i', {
    staticClass: "iconfont icon-kaoshi"
  }), _vm._v("历史考生")])]);
}];
export { render, staticRenderFns };